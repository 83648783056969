import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not found" />
		<div className="page-headline">
			<div className="container">
				<div className="section-heading text-center">
					<h6 className="font-weight-bold text-uppercase flair">ERROR 404</h6>
					<h1>
						<strong>Page not found.</strong>
					</h1>
				</div>
			</div>
			<div className="headline-bg" />
		</div>
		<div className="page-content">
			<div className="container">
				<div className="text-center">
					<p className="mb-0 lead">
						<strong>The page you are looking for does not exist.</strong>
					</p>
					<div className="mt-5">
						<Link className="readmore-btn section-footer sched-modal" to="/">
							Take me Home
						</Link>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default NotFoundPage
